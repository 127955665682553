<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Bảng kê chi hộ</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'e']" @click="exportExcel()" class="md-raised md-primary btn-add">
                            Export<md-tooltip>Xuất excel</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately>
                                <label>Từ ngày thực hiện</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately>
                                <label>Tới ngày thực hiện</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-4 m-4 c-12">
                            <md-field>
                                <label>Tìm theo số cont</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content">
                    <div style="overflow: auto; width: 100%;">
                        <table class="data-table" style="width: 100% !important;">
                            <thead>
                                <tr>
                                    <th style="width:50px;">#</th>
                                    <th style="width:200px;">Khách hàng</th>
                                    <th style="width:200px;">Đơn hàng</th>
                                    <th style="width:120px;">Ngày lập</th>
                                    <th style="width:200px;">Bill/booking</th>
                                    <th style="width:100px;">Số cont/kiện</th>
                                    <th style="width:200px;">Chi hộ</th>
                                    <th style="width:100px;">Số lượng</th>
                                    <th style="width:120px;">Đơn giá</th>
                                    <th style="width:100px;">Vat</th>
                                    <th style="width:120px;">Thành tiền</th>
                                    <th style="width:120px;">Số HĐ</th>
                                    <th style="width:120px;">Ngày HĐ</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-if="loadding == false && data.length > 0">
                                <tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
                                    <td class="center">{{index + 1}}</td>
                                    <td class="left">{{ item.customerName }}</td>
                                    <td class="left">{{ item.orderCode }}</td>
                                    <td class="left">{{ item.orderDate }}</td>
                                    <td class="left">{{ item.noBooking }}</td>
                                    <td class="left">{{ item.serial }}</td>
                                    <td class="left">{{ item.feeName }}</td>
                                    <td class="center">
                                        {{ formatNumber(item.quantity) }}
                                    </td>
                                    <td class="right">
                                        {{ formatNumber(item.price) }}
                                    </td>
                                    <td class="right">
                                        {{ formatNumber(item.vat) }}%
                                    </td>
                                    <td class="right">
                                        {{ formatNumber(item.amount) }}
                                    </td>
                                    <td class="right">{{ item.invoiceNumber }}</td>
                                    <td class="right">{{ item.invoiceDate }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <orderList ref="orderList" @close="closeOrder"/>
    </div>
</template>
<script>
    import reportKtktService from '../../../api/reportKtktService';
    import companyService from '../../../api/companyService';
    import branchService from '../../../api/branchService';
    import orderService from '../../../api/orderService';
    import messageBox from '../../../utils/messageBox';
    import common from '../../../mixins';
    import { mapActions } from 'vuex';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import orderList from '../../../components/popup/_OrderList.vue';
    import { APP_CONFIG } from '@/utils/constants';

    export default {
        components: {
            customerList,
            orderList
        },
        metaInfo: {
            title: 'Bảng kê chi hộ'
        },
        data() {
            return {
                loadding: false,
                search: { fromDate: this.getRangeDate(-7), toDate: common.dateNow, branchId: 0, orderId: 0, customerId: 0, keyword: '' },
                data: [],
                id: 0,
                branchs: [],
                customerName: '',
                customers: [],
                orderName: '',
                orders: [],
                customerType: common.companyType.customer,
            }
        },
        created(){
            this.getBranchs();
        },
        mounted() {
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            exportExcel(){
                const link = document.createElement('a')
                link.href = APP_CONFIG.apiUrl + "/report-ktkt/export-report-fee-on-behalf?fromDate=" + 
                this.search.fromDate + "&toDate=" + this.search.toDate + "&branchId=" + this.search.branchId
                + "&customerId=" + this.search.customerId
                + "&orderId=" + this.search.orderId
                + "&keyword=" + this.search.keyword;

                link.target = '_blank';
                link.click()
                URL.revokeObjectURL(link.href)
            },

            refresh(){
                this.search = { fromDate: this.getRangeDate(-7), toDate: common.dateNow, branchId: 0, orderId: 0, customerId: 0, keyword: '' };
                this.getData();
            },

            filter(){
                this.$router.push('/report-fee-on-behalf').catch(()=>{});

                this.getData();
            },

            getData(){
                this.loadding = true;
                reportKtktService.getReportFeeOnBehalf(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; });
            },

            //Order
            closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

             //Customer
             closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open();
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: {
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
        }
    }

</script>
